<template>
  <div class="Receiving bj">
    <div class="inputtop">
      <el-input
        style=""
        clearable
        v-model="code"
        placeholder="请在此输入或扫描订单复核码"
        autocomplete="off"
        autofocus="true"
        ref="inputfocusc"
        @keyup.enter.native="changeInput"
      />
      <div class="seach">
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
    </div> 
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        style="width: 100%"
      >
        <el-table-column
          prop="receive_order_no"
          align="center"
          label="订单编号"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="客户姓名">
          <template slot-scope="scope">
            <span v-if="scope.row.order&&scope.row.order.member">{{ $empty.empty(scope.row.order.member.fullname) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="客户电话">
          <template slot-scope="scope">
            <span v-if="scope.row.order&&scope.row.order.member">{{ $empty.empty(scope.row.order.member.mobile) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="自提点名称">
          <template slot-scope="scope">
            <span v-if="scope.row.order&&scope.row.order.delivery">{{ $empty.empty(scope.row.order.delivery.name) }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy centseis">
      <el-button type="primary" @click="hexiao">点击核销</el-button>
    </div>
  </div>
</template>
  <script>
export default {
  name: "Receiving",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      code: "",
      value1: [],
      tableData: [],
      currentPage: 1,
      total: 0,
      multipleSelection: [],
    };
  },
  created() {
    // this.hqlist();
  },
  mounted() {
    this.$refs.inputfocusc.focus();
  },
  methods: {
    search() {
      this.hqlist();
    },
    changeInput() {
      this.hqlist();
    },
    hexiao() {
      let _this = this;
      let arr = JSON.parse(JSON.stringify(this.tableData));
      this.$confirm("确认要核销吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$api.delivery
            .ordercomplete({
              order_nos: arr.map((el) => el.order_no),
            })
            .then((res) => {
              _this.$message({
                type: "success",
                message: "核销成功!",
              });
              this.$refs.inputfocusc.focus();
              this.code = "";
              this.tableData = [];
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消核销",
          });
        });
    },
    hqlist() {
      // this.loading = true;
      // this.loading = false;
      this.$api.delivery.tihuodanhexiao({ order_no: this.code }).then((res) => {
        console.log(res, "获取数据");
        let count = 0
        res.data.forEach(item1=>{
          if(item1.check_status==1){
            count++
          }
        })
        if(count==0){
          this.$message.error("该订单已核销");
          return
        }
        this.tableData = this.tableData.concat(res.data.filter(item=>{
          return item.check_status==1
        }))
        // if (res.data.check_status == 1) {
        //   // this.tableData.unshift(res.data);
        //   this.tableData = this.tableData.concat(res.data)
        // } else {
        //   this.$message.error("该订单已核销");
        // }
        const map = new Map();
        const qc = this.tableData.filter(
          (key) => !map.has(key.id) && map.set(key.id, 1)
        );
        this.tableData = qc;
        this.$refs.inputfocusc.focus();
        this.code = "";
      });
    },
  },
};
</script>
  <style lang="scss">
.Receiving {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .inputtop {
    display: flex;
    margin-bottom: 6px;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: 8px;
    }
  }
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
.centseis {
  text-align: center;
}
</style>
  